import { parseUnits } from "viem";
import { BASE_URL } from "./constants/domains";

export const BSC_BLOCK_TIME = 3;

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365; // 10512000
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`;
export const DEFAULT_TOKEN_DECIMAL = parseUnits("1", 18);
export const DEFAULT_GAS_LIMIT = BigInt(500_000);
export const AUCTION_BIDDERS_TO_FETCH = 500;
export const RECLAIM_AUCTIONS_TO_FETCH = 500;
